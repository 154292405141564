import React from "react";
import Helmet from 'react-helmet';

export default ({ children }) => (
  <div>
  	<Helmet title={'Personal writings by Spiros Martzoukos'} />
  	<div style={{ margin: `2em auto`, maxWidth: 650, padding: `0 1rem` }}>
    	{children}
    </div>
  </div>
);
